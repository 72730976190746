<template>
  <v-container>
    <SessionSuspended />
    <SessionCreditsFooter />
  </v-container>
</template>

<script>
import { projectCustomComponent } from '@utils/project.js'
export default {
  page: {
    title: 'Sessione Sospesa',
  },
  components: {
    SessionCreditsFooter: () =>
      projectCustomComponent('SessionCreditsFooter.vue'),
    SessionSuspended: () => projectCustomComponent('SessionSuspended.vue'),
  },
}
</script>
